/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.locale = document.documentElement.lang;

import Lang from './utils/Lang';
window.Lang = Lang;

import initButtonForm from './utils/ButtonForm';
initButtonForm();

window.animate('.alert.auto', ['fadeOutUp', 'delay-5s'], (element) => {
    $(element).alert('close');
});
