/*
    Example anchor markup
    <a href="{{ route('this.is.the.route', [$p1, $p2]) }}" class="classes"
       data-method="delete|patch|post|put"
       data-token="{{ csrf_token() }} "
       data-confirm="@lang('Message from locale')">
*/

const handle = event => {
    event.preventDefault();
    const $anchor = event.target;

    const http_method = $anchor.dataset.method;
    if (!['patch', 'post', 'put', 'delete'].includes(http_method)) {
        return;
    }

    const confirm_message = $anchor.dataset.confirm;
    if (confirm_message && !confirm(confirm_message)) {
        return false;
    }
    console.log();
    let $form = document.createElement('form');
    $form.method = 'POST';
    $form.action = $anchor.getAttribute('href');

    let $token = document.createElement('input');
    $token.type = 'hidden';
    $token.name = '_token';
    $token.value = $anchor.dataset.token;

    let $method = document.createElement('input');
    $method.type = 'hidden';
    $method.name = '_method';
    $method.value = $anchor.dataset.method;

    console.log($form, $token, $method);

    $form.appendChild($token);
    $form.appendChild($method);
    document.body.appendChild($form);
    $form.submit();
};

const init = () => {
    document
        .querySelectorAll('a[data-method]')
        .forEach(anchor => {
            anchor.addEventListener('click', event => handle(event));
        });
};

export default init;
